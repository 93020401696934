import {
  Flex,
  Icon,
  TopNavigationActionButton,
  TopNavigationActionButtonGroup,
  TopNavigationButton,
  useIsWindowSizeBelowBreakpoint,
  TOP_NAVIGATION_COLOR,
} from "@taxbit-private/cosmic";
import { useTranslation } from "react-i18next";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";

import AppNotifications from "./notifications/AppNotifications";
import { usePageTitle } from "./PageTitleUpdater";
import TaxbitLogoSvg from "../assets/header.svg";
import { useAuthentication } from "../auth/useAuthentication";
import useDartsStore from "../store/useDartsStore";
import { useCaseFilterQueryParamContext } from "../utils/CaseFilterQueryParamsCacheProvider";

const Shell: React.FC = () => {
  usePageTitle();
  const location = useLocation();

  const toggleMenu = useDartsStore((state) => state.toggleMenu);
  const isMenuVisible = useDartsStore((state) => state.isMenuVisible);
  const clearToasts = useDartsStore((state) => state.clearToasts);

  const isMobile = useIsWindowSizeBelowBreakpoint("tablet");
  const { caseFilterQueryParamsCache } = useCaseFilterQueryParamContext();
  const navigate = useNavigate();

  const { signOut } = useAuthentication();

  const { t } = useTranslation();

  return (
    <Flex direction="column">
      <HeaderContainer>
        {location.pathname === "/case-list" ? (
          <div />
        ) : (
          <PositionedButton
            onClick={() => {
              clearToasts();
              navigate(`/case-list${caseFilterQueryParamsCache}`);
            }}
            trackingId="back-to-all-cases"
          >
            <Flex alignItems="center" gap="s" padding="s">
              <Icon name="arrow-left" />
              {isMobile ? t("All") : t("Back to all cases", { ns: "case" })}
            </Flex>
          </PositionedButton>
        )}
        <StyledImg src={TaxbitLogoSvg} alt="Taxbit Logo" />
        <PositionedActionButtonGroup>
          {location.pathname !== "/case-list" && isMobile && (
            <TopNavigationActionButton
              iconName="menu"
              trackingId="open-close-navigation"
              onClick={toggleMenu}
              isMenuOpen={isMenuVisible}
            />
          )}
          <TopNavigationActionButton
            onClick={signOut}
            iconName="log-out"
            trackingId="logout-button"
            label={t("Log out", { ns: "app" })}
          />
        </PositionedActionButtonGroup>
      </HeaderContainer>
      <AppNotifications />
      <Outlet />
    </Flex>
  );
};

export default Shell;

const HeaderContainer = styled(Flex)(
  ({ theme }) => `
  background: ${TOP_NAVIGATION_COLOR};
  height: ${theme.sizing.xl};
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 3;
  min-width: calc(100% - 2 * (${theme.spacing.l} + ${theme.spacing.m}));
  padding: ${theme.spacing.none} ${theme.spacing.m};
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  align-items: center;
`
);

const PositionedButton = styled(TopNavigationButton)`
  justify-self: start;
`;

const StyledImg = styled.img(
  ({ theme }) => `
  height: ${theme.sizing.s};
  justify-self: center;
`
);

const PositionedActionButtonGroup = styled(TopNavigationActionButtonGroup)`
  justify-self: end;
`;

import axios from "axios";
import { z } from "zod";

import { getAccessToken } from "./utils/getAccessToken";
import { getHeaders } from "./utils/getHeaders";
import { getRequestBody } from "./utils/getRequestBody";
import isErrorResponse from "./utils/handleCustomErrorResponse";
import HttpError, { HttpErrorCode } from "./utils/httpError";
import { JsonData, JsonSchema } from "./utils/jsonDataTypes";
import { ProjectError } from "../utils/ApiError";

type HttpMethod = "GET" | "POST" | "PUT" | "PATCH" | "DELETE";

type MakeRequestOptions<T extends JsonSchema, U extends JsonSchema> = {
  baseUrl: string;
  requestHeaders?: Headers;
  urlPath?: string;
  method?: HttpMethod;
  urlSearchParams?: URLSearchParams;
  requestData?: JsonData | FormData;
  responseDataSchema?: T;
  requestDataSchema?: U;
};

// A "conditional type", which can infer whether or not a reponseDataSchema is provided
type ResponseTypes<T> = T extends { responseDataSchema: JsonSchema }
  ? z.infer<T["responseDataSchema"]>
  : undefined;

export async function makeApiRequest<
  T extends MakeRequestOptions<JsonSchema, JsonSchema>
>({
  baseUrl,
  requestHeaders,
  urlPath,
  method,
  urlSearchParams,
  requestData,
  responseDataSchema,
  requestDataSchema,
}: T): Promise<ResponseTypes<T>> {
  const token = await getAccessToken();

  const headers = getHeaders(requestHeaders, token);
  const body = getRequestBody(requestData, requestDataSchema);

  if (body && requestDataSchema && !headers.get("content-type"))
    headers.set("content-type", "application/json");

  const queryString = urlSearchParams ? `?${urlSearchParams}` : "";
  const urlWithPath = urlPath ? `${baseUrl}/${urlPath}` : baseUrl;
  const fullUrl = `${urlWithPath}${queryString}`;

  const response = await fetch(fullUrl, {
    method,
    headers,
    body,
  }).catch((e) => {
    console.error(e);
    throw new Error(`Network error calling ${baseUrl}: ${e}`);
  });

  // The below type casts are currently a limitation on the typescript compiler
  // Using unspecified generic types with a conditional type, it cannot infer
  // Issue details here: https://github.com/microsoft/TypeScript/issues/33912
  // Helpful discussion here: https://stackoverflow.com/a/66553240
  if (responseDataSchema) {
    const res = (await response.json()) as z.infer<typeof responseDataSchema>;

    if (response.status === HttpErrorCode.BAD_REQUEST && isErrorResponse(res)) {
      throw new ProjectError({
        errors: res.errors,
        message: res.errorMessage,
        statusCode: HttpErrorCode.BAD_REQUEST,
      });
    } else if (!response.ok) {
      if (response.status === HttpErrorCode.FORBIDDEN) {
        // Redirect to the unauthorized page
        window.location.href = "/unauthorized";
      } else if (response.status === HttpErrorCode.CONFLICT) {
        throw new HttpError(
          HttpErrorCode.CONFLICT,
          "There was a conflict with your request."
        );
      } else {
        console.error(`Request returned status code: ${response.status}`);
        throw new Error(
          `There was an error with your request, please try again.`
        );
      }
    }

    return responseDataSchema.parse(res) as ResponseTypes<T>;
  } else {
    // Intentionally return undefined if a responseDataSchema is not provided
    return undefined as ResponseTypes<T>;
  }
}

// TODO: Eliminate in favor of the zod typed makeRequest above
type OldRequestOptions = {
  method: HttpMethod;
  url: string;
  id?: string;
  params?: Record<string, any>;
  data?: object | string;
  config?: {
    headers: {
      "content-type": string;
    };
  };
};

// TODO: Eliminate in favor of the zod typed makeRequest above
export const useOldMakeRequest = () => {
  return async <T>({
    url,
    method,
    params,
    data,
    config,
  }: OldRequestOptions) => {
    const newURL = params
      ? `${url}?${new URLSearchParams(params).toString()}`
      : url;
    const token = await getAccessToken();
    const contentType = config
      ? config?.headers["content-type"]
      : "application/json";

    const options = {
      url: newURL,
      method,
      headers: {
        "content-type": contentType,
        Authorization: `Bearer ${token}`,
      },
      data,
    };
    return axios.request<T>(options);
  };
};

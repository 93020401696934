import { useCallback, useEffect } from "react";
import { useMatch } from "react-router-dom";

export const usePageTitle = () => {
  const unauthorizedMatch = useMatch("/unauthorized");
  const caseListMatch = useMatch("/case-list");
  const caseDetailMatch = useMatch("/case-list/:caseId");
  const caseReportListMatch = useMatch("/case-list/:caseId/report-list");
  const caseSettingMatch = useMatch("/case-list/:caseId/case-settings");
  const caseTransactionsMatch = useMatch("/case-list/:caseId/transactions");
  const caseTransactionMatch = useMatch(
    "/case-list/:caseId/transactions/:transactionId"
  );
  const caseEditTransactionMatch = useMatch(
    "/case-list/:caseId/transactions/:transactionId/edit"
  );
  const caseTransactionAssetMatch = useMatch(
    "/case-list/:caseId/transactions/:transactionId/assets"
  );
  const caseSourcesMatch = useMatch("/case-list/:caseId/sources");
  const caseSourceErrorsMatch = useMatch(
    "/case-list/:caseId/sources/:sourceId"
  );
  const caseAddSourceMatch = useMatch("/case-list/:caseId/sources/add");
  const caseAddSummonMatch = useMatch("/case-list/:caseId/sources/add/summon");
  const caseAddGenericCsvMatch = useMatch(
    "/case-list/:caseId/sources/add/generic-csv"
  );
  const caseAddJsonLFileMatch = useMatch(
    "/case-list/:caseId/sources/add/jsonl"
  );
  const caseAddAddressFileMatch = useMatch(
    "/case-list/:caseId/sources/add/public-address"
  );
  const caseResolutionCenterMatch = useMatch(
    "/case-list/:caseId/resolution-center/:selectedTab"
  );
  const caseSummaryMatch = useMatch("/case-list/:caseId/case-summary");
  const linkTransferMatch = useMatch("/case-list/:caseId/link-transfers");

  const getTitle = useCallback(() => {
    if (unauthorizedMatch) {
      return "DARTS Unauthorized";
    } else if (caseListMatch) {
      return "DARTS Case List - View All Cases";
    } else if (caseDetailMatch) {
      return "DARTS Case - View Case Details";
    } else if (caseSummaryMatch) {
      return "DARTS Case Summary";
    } else if (caseReportListMatch) {
      return "DARTS Reports - Generate and Download Case Reports";
    } else if (linkTransferMatch) {
      return "DARTS Link Transfers - View Link Transfers";
    } else if (caseSettingMatch) {
      return "DARTS Settings - Configure Case Options";
    } else if (caseTransactionsMatch) {
      return "DARTS Transactions - Browse Case Activity List";
    } else if (caseTransactionMatch) {
      return "DARTS Transaction - View Transaction Details";
    } else if (caseEditTransactionMatch) {
      return "DARTS Transaction - Edit Transaction Details";
    } else if (caseSourcesMatch) {
      return "DARTS Sources - Manage Case Data Sources";
    } else if (caseSourceErrorsMatch) {
      return "DARTS Source Errors - View Source Errors";
    } else if (
      caseAddSourceMatch ||
      caseAddSummonMatch ||
      caseAddGenericCsvMatch ||
      caseAddJsonLFileMatch ||
      caseAddAddressFileMatch
    ) {
      return "DARTS Add Sources - Add New Data Sources";
    } else if (caseTransactionAssetMatch) {
      return "DARTS Transaction Assets - Identify Unknown Assets";
    } else if (caseResolutionCenterMatch) {
      return "DARTS Resolution Center";
    }

    return "DARTS";
  }, [
    caseAddGenericCsvMatch,
    caseAddJsonLFileMatch,
    caseAddAddressFileMatch,
    caseAddSourceMatch,
    caseAddSummonMatch,
    caseDetailMatch,
    caseListMatch,
    caseReportListMatch,
    caseSourceErrorsMatch,
    caseSourcesMatch,
    caseSummaryMatch,
    caseTransactionsMatch,
    caseTransactionMatch,
    caseEditTransactionMatch,
    caseTransactionAssetMatch,
    unauthorizedMatch,
    caseSettingMatch,
    caseResolutionCenterMatch,
    linkTransferMatch,
  ]);

  useEffect(() => {
    document.title = getTitle();
  }, [getTitle]);
};

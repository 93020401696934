import { Flex } from "@taxbit-private/cosmic";
import React from "react";
import { useLocation } from "react-router-dom";
import styled from "styled-components";

import TimedToast from "./TimedToast";
import useDartsStore from "../../store/useDartsStore";
import getSidebarNavWidth from "../../utils/getSideNavBarWidth";

const ToastNotifications: React.FC = () => {
  const toasts = useDartsStore((state) => state.toasts);
  const location = useLocation();

  if (toasts.length === 0) {
    return <></>;
  }

  const isSidebarDisplayed = location.pathname !== "/case-list";

  return (
    <ToastsWrapper
      isSidebarDisplayed={isSidebarDisplayed}
      aria-live="polite"
      aria-atomic="true"
      padding="l"
      gap="s"
      direction="column"
    >
      {toasts.map((toast) => (
        <TimedToast key={toast.uuid} {...toast} />
      ))}
    </ToastsWrapper>
  );
};

const ToastsWrapper = styled(Flex).withConfig<{
  isSidebarDisplayed: boolean;
}>({
  shouldForwardProp: (propName) => {
    return propName !== "isSidebarDisplayed";
  },
})(
  ({ theme, isSidebarDisplayed }) => `
  position: fixed;
  bottom: 0px;
  left: ${isSidebarDisplayed ? getSidebarNavWidth(theme) : "0px"};
  width: calc(100% - ${isSidebarDisplayed ? getSidebarNavWidth(theme) : "0px"});
  z-index: ${theme.zIndex.toast};
`
);
export default ToastNotifications;

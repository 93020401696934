import { useEffect, useState } from "react";

import { getCurrentTenantId } from "../../api/utils/getAccessToken";

/**
 * This hook determines whether the current tenant id is included in the set of tenant ids given
 * @returns A boolean indicating whether the current tenant is included
 */
const useTenantIdDoesMatch = (tenantIdsToMatch: Set<string>) => {
  const [currentTenantId, setCurrentTenantId] = useState<string | undefined>(
    undefined
  );

  useEffect(() => {
    const fetchTenantId = async () => {
      setCurrentTenantId(await getCurrentTenantId());
    };
    void fetchTenantId();
  });

  return tenantIdsToMatch.has(currentTenantId ?? "");
};

export default useTenantIdDoesMatch;

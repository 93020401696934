import { ContentSpinner } from "@taxbit-private/cosmic";
import { Suspense, ReactNode } from "react";

const RouteComponent: React.FC<{ children: ReactNode }> = ({ children }) => {
  return (
    <Suspense
      fallback={<ContentSpinner trackingId="route-component-spinner" />}
    >
      {children}
    </Suspense>
  );
};

export default RouteComponent;

import {
  cosmicSupportedCurrencies,
  cosmicSupportedLocales,
} from "@taxbit-private/cosmic-localization";
import { z } from "zod";

import { assetSchema } from "../../../types/data-models-v2/asset";

export const cosmicSupportedCurrencySchema = z.enum(cosmicSupportedCurrencies);
export const cosmicSupportedLocaleSchema = z.enum(cosmicSupportedLocales);

export const tenantMetadataSchema = z.object({
  locale: cosmicSupportedLocaleSchema,
  preferredFiatAsset: assetSchema,
});

export type GetTenantMetadataResponse = z.infer<typeof tenantMetadataSchema>;

import {
  Flex,
  H3,
  H4,
  getSharedBorderStyle,
  Navigation,
  getMediaQueryForBelowBreakpoint,
} from "@taxbit-private/cosmic";
import { useCosmicLocalizationContext } from "@taxbit-private/cosmic-localization";
import { useCallback, useEffect, useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";

import BannerNotification from "./notifications/BannerNotification";
import { useGetCase } from "../api/endpoints/cases/caseGetApi";
import {
  useGetCaseStatus,
  isCaseStatusInProgress,
} from "../api/endpoints/cases/caseStatusGetApi";
import { CaseStatus } from "../api/endpoints/cases/caseStatusGetApiTypes";
import { Case } from "../api/endpoints/cases/caseTypes";
import { useGetFiles } from "../api/endpoints/sources/filesGetApi";
import {
  isSourceStatusInProgress,
  isStatusDeleteInProgress,
} from "../api/endpoints/sources/utils/sourceStatus";
import { formatDateString } from "../pages/case-list/utils/formatDateString";
import useDartsStore from "../store/useDartsStore";
import { useGetBannerMessage } from "../utils/getBannerMessage";
import getSidebarNavWidth from "../utils/getSideNavBarWidth";
import { useFlags } from "../utils/hooks/useFlags";
import useLocalStorageState from "../utils/hooks/useLocalStorageState";

const AppNavigation: React.FC = () => {
  const { isCaseSummaryEnabled } = useFlags();
  const { t } = useTranslation("case");
  const { formatDateTime } = useCosmicLocalizationContext();
  const { getBannerMessage } = useGetBannerMessage();

  const NAV_ITEMS = useMemo(
    () =>
      [
        ...(isCaseSummaryEnabled
          ? ([
              {
                label: t("Case Summary"),
                trackingId: "case-summary",
                iconName: "user",
                href: "case-summary",
              },
            ] as const)
          : ([] as const)),
        {
          label: t("Transactions", { ns: "transactions" }),
          trackingId: "transactions",
          iconName: "book-open",
          href: "transactions",
        },
        {
          label: t("Sources", { ns: "sources" }),
          trackingId: "source",
          iconName: "link",
          href: "sources/generic-csv",
        } /*
        {
          label: "Resolution Center",
          trackingId: "resolution-center",
          iconName: "sliders",
          href: "resolution-center/files",
        }, */,
        {
          label: t("Reports", { ns: "reports" }),
          trackingId: "reports",
          iconName: "file-text",
          href: "report-list",
        },
        {
          label: t("Link Transactions"),
          trackingId: "link-transactions",
          iconName: "globe",
          href: "link-transactions",
        },
        {
          label: t("Settings"),
          trackingId: "case-settings",
          iconName: "settings",
          href: "case-settings",
        },
      ] as const,
    [isCaseSummaryEnabled, t]
  );
  const navigate = useNavigate();
  const location = useLocation();
  const { caseId } = useParams();

  const addBanner = useDartsStore((state) => state.setBanner);
  const clearBanner = useDartsStore((state) => state.clearBanners);
  const isMenuVisible = useDartsStore((state) => state.isMenuVisible);
  const toggleMenu = useDartsStore((state) => state.toggleMenu);

  const { data: caseData, isLoading } = useGetCase(caseId as Case["id"]);
  const { data: caseTaxCalc, isLoading: isCaseStatusLoading } =
    useGetCaseStatus(caseId as string);
  const { data: sources, isLoading: isSourcesLoading } = useGetFiles(
    caseId as Case["id"]
  );

  const addToast = useDartsStore((state) => state.addToast);
  const isTaxCalcsFailedToastShown = useRef<boolean>(false);
  const [taxCalcsFailedToastDismissed, setTaxCalcsFailedToastDismissed] =
    useLocalStorageState<string[]>("tb.darts.taxCalcsFailedToastDismissed", []);

  const createNavigationItem = useCallback(
    ({ href, ...item }: (typeof NAV_ITEMS)[number]) => ({
      ...item,
      isActive: location.pathname.includes(`/${href.split("/")[0]}`),
      onClick: () => {
        navigate(`${href}`);
        if (isMenuVisible) {
          toggleMenu();
        }
      },
    }),
    [location.pathname, navigate, isMenuVisible, toggleMenu]
  );

  const completeModuleNavigationItems = useMemo(
    () => NAV_ITEMS.map((item) => createNavigationItem(item)),
    [createNavigationItem, NAV_ITEMS]
  );

  useEffect(() => {
    if (
      !isLoading &&
      caseData?.calcsFailedTimestamp &&
      !isTaxCalcsFailedToastShown.current
    ) {
      const failedKey = `${caseData.id}_${caseData.calcsFailedTimestamp}`;
      if (!taxCalcsFailedToastDismissed.includes(failedKey)) {
        isTaxCalcsFailedToastShown.current = true;
        addToast({
          content: t("LastTaxCalcFailed", {
            timestamp: formatDateTime({
              date: caseData.calcsFailedTimestamp,
              format: "DateTime",
            }),
          }),
          variant: "danger",
          autoHide: false,
          onClose: () => {
            setTaxCalcsFailedToastDismissed([
              ...taxCalcsFailedToastDismissed,
              failedKey,
            ]);
            isTaxCalcsFailedToastShown.current = false;
          },
        });
      }
    }
  }, [
    isLoading,
    caseData,
    addToast,
    taxCalcsFailedToastDismissed,
    setTaxCalcsFailedToastDismissed,
    formatDateTime,
    t,
  ]);

  useEffect(() => {
    if (!isSourcesLoading && !isCaseStatusLoading) {
      const calcsInProgress =
        caseTaxCalc &&
        isCaseStatusInProgress(caseTaxCalc.caseStatus as CaseStatus);
      const sourcesInProgress = sources
        ? sources.filter(
            (source) =>
              isSourceStatusInProgress(source.status) ||
              isStatusDeleteInProgress(source.status)
          )
        : [];
      if (sourcesInProgress.length > 0 || calcsInProgress) {
        addBanner(caseId as string, {
          message: getBannerMessage(sourcesInProgress),
          shouldShowSpinner: true,
        });
      } else {
        clearBanner();
      }
    }
  }, [
    addBanner,
    caseId,
    caseTaxCalc,
    clearBanner,
    sources,
    isSourcesLoading,
    isCaseStatusLoading,
    getBannerMessage,
  ]);

  return (
    <Flex>
      <NavWrapper
        direction="column"
        padding="xxl m m none"
        gap="xl"
        isMenuVisible={isMenuVisible}
      >
        <StyledHeaderContainer direction="column">
          {!isLoading && caseData && (
            <>
              <H3>{caseData.caseName}</H3>
              {caseData?.asedDate && (
                <H4>
                  {t("DUE DATE", { ns: "case" })}:{" "}
                  {formatDateString(caseData.asedDate)}
                </H4>
              )}
            </>
          )}
        </StyledHeaderContainer>
        <Flex direction="column" gap="l">
          <Navigation items={completeModuleNavigationItems} />
        </Flex>
      </NavWrapper>
      <NonNavContent isMenuVisible={isMenuVisible}>
        <FixedNonNavContent>
          <BannerNotification />
        </FixedNonNavContent>
        <Outlet />
      </NonNavContent>
    </Flex>
  );
};

export default AppNavigation;

const NavWrapper = styled(Flex).withConfig<{
  isMenuVisible?: boolean;
}>({
  shouldForwardProp: (propName) => !["isMenuVisible"].includes(propName),
})(
  ({ theme, isMenuVisible }) => `
  background-color: ${theme.color.white};
  border-right: ${getSharedBorderStyle(theme)};
  width: ${getSidebarNavWidth(theme)};
  position: fixed;
  top: 0;
  height: 100vh;
  z-index: 2;
  overflow: auto;
  ${getMediaQueryForBelowBreakpoint(theme, "tablet")} {
     ${isMenuVisible ? "display: flex;" : "display: none;"}
  }
`
);

const NonNavContent = styled.div.withConfig<{
  isMenuVisible?: boolean;
}>({
  shouldForwardProp: (propName) => !["isMenuVisible"].includes(propName),
})(
  ({ theme, isMenuVisible }) => `
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  background: ${theme.color.gray0};
  width: 100%;
  margin-top: ${theme.sizing.xl};
  margin-left: ${getSidebarNavWidth(theme)};

 ${getMediaQueryForBelowBreakpoint(theme, "tablet")} {
     margin-left: ${
       isMenuVisible
         ? `calc(${getSidebarNavWidth(theme)} + ${theme.spacing.m})`
         : 0
     };


  }
`
);

const FixedNonNavContent = styled.div(
  ({ theme }) => `
  position: sticky;
  top: ${theme.sizing.xl};
  background-color: ${theme.color.white};
  z-index: 1;
`
);

const StyledHeaderContainer = styled(Flex)(
  ({ theme }) => `
    margin-top: ${theme.sizing.m};
    padding-left: ${theme.spacing.l};
    min-height: ${theme.sizing.l};
    width: ${theme.measure.s};
`
);

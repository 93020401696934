import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useState } from "react";

import {
  CaseStatusEnum,
  CaseStatus,
  caseStatusSchema,
} from "./caseStatusGetApiTypes";
import { Case } from "./caseTypes";
import { getBaseCaseApiUrl } from "../../../utils/environment";
import { makeApiRequest } from "../../useMakeRequest";
import pathBuilders from "../../utils/pathBuilder";
import queryKeys from "../../utils/queryKeys";

type getStatusParams = {
  caseId: Case["id"];
};

const IN_PROGRESS_CASE_STATUS_POLLING_IN_MS = 3_000;
const IDLE_CASE_STATUS_POLLING_IN_MS = 30_000;

const isAnyCalcInProgress = (data: CaseStatusEnum | undefined) =>
  data && isCaseStatusInProgress(data.caseStatus as CaseStatus);

export const isCaseStatusInProgress = (caseStatus: CaseStatus) =>
  [
    CaseStatus.TAX_ENGINE_SCHEDULED,
    CaseStatus.TAX_ENGINE_IN_PROGRESS,
    CaseStatus.TRANSFER_DETECTION_IN_PROGRESS,
    CaseStatus.FILE_INGESTION_IN_PROGRESS,
  ].includes(caseStatus);

const getStatus = async ({ caseId }: getStatusParams) => {
  const baseUrl = getBaseCaseApiUrl();

  return makeApiRequest({
    baseUrl,
    urlPath: caseId && pathBuilders.buildCaseStatusPath(caseId),
    responseDataSchema: caseStatusSchema,
  });
};

export const useGetCaseStatus = (caseId: Case["id"]) => {
  const queryClient = useQueryClient();
  const [wasProcessing, setWasProcessing] = useState(false);
  return useQuery({
    queryKey: queryKeys.getCaseStatusKey(caseId),
    queryFn: async () => {
      const res = await getStatus({ caseId });
      if (res.caseStatus === CaseStatus.IDLE && wasProcessing) {
        await queryClient.invalidateQueries(queryKeys.getCaseKey(caseId));
        setWasProcessing(false);
      } else if (isCaseStatusInProgress(res.caseStatus as CaseStatus)) {
        setWasProcessing(true);
      } else if (res.caseStatus === CaseStatus.TAX_ENGINE_FAILED) {
        await queryClient.refetchQueries(queryKeys.getCaseKey(caseId));
      }
      return res;
    },
    refetchInterval: (data) =>
      isAnyCalcInProgress(data)
        ? IN_PROGRESS_CASE_STATUS_POLLING_IN_MS
        : IDLE_CASE_STATUS_POLLING_IN_MS,
  });
};

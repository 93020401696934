import { useQuery, useQueryClient } from "@tanstack/react-query";

import {
  FileUpload,
  GetFilesRequest,
  GetFilesResponse,
  getFilesApiResponseSchema,
} from "./fileGetApiTypes";
import {
  isSourceStatusInProgress,
  isStatusDeleteInProgress,
  isStatusInitialized,
} from "./utils/sourceStatus";
import { getBaseCaseApiUrl } from "../../../utils/environment";
import { makeApiRequest } from "../../useMakeRequest";
import pathBuilders from "../../utils/pathBuilder";
import queryKeys from "../../utils/queryKeys";
import { Case } from "../cases/caseTypes";

type GetCaseFilesParams = {
  caseId: Case["id"];
  requestParams?: GetFilesRequest;
  queryClient: ReturnType<typeof useQueryClient>;
};

const FILE_POLLING_IN_MS = 3_000;
const INITIAL_UPLOAD_WAIT_TIME_IN_MS = 10_000;

const isAnySourceInitialized = (files: FileUpload[] | undefined) =>
  files?.some((file) => isStatusInitialized(file.status));

const isAnySourceUploadInProgress = (files: FileUpload[] | undefined) =>
  files?.some((file) => isSourceStatusInProgress(file.status));

const isAnySourceDeleting = (files: FileUpload[] | undefined) =>
  files?.some((file) => isStatusDeleteInProgress(file.status));

export const addContinuationTokenToRequestParams = (
  continuationToken?: string | null,
  requestParams?: GetFilesRequest
): GetFilesRequest | undefined => {
  return continuationToken
    ? { ...requestParams, continuationToken }
    : requestParams;
};

export const getCaseFiles = async ({
  caseId,
  requestParams,
  queryClient,
}: GetCaseFilesParams): Promise<GetFilesResponse> => {
  const baseUrl = getBaseCaseApiUrl();
  let allSources: FileUpload[] = [];

  const getSources = async (continuationToken?: string) => {
    const params = addContinuationTokenToRequestParams(
      continuationToken,
      requestParams
    );

    const res = await makeApiRequest({
      baseUrl,
      urlPath: pathBuilders.buildFilesPath(caseId, params),
      responseDataSchema: getFilesApiResponseSchema,
    });

    allSources = [...allSources, ...res.sources];

    if (res.continuationToken) {
      await getSources(res.continuationToken);
    }
  };

  await getSources();

  // If there are no files in progress, let's refetch the case status
  // This will help ensure that the banner correctly re-appears in the app
  if (
    !isAnySourceUploadInProgress(allSources) &&
    !isAnySourceDeleting(allSources)
  ) {
    void queryClient.refetchQueries(queryKeys.getCaseStatusKey(caseId));
  }

  return allSources;
};

export const useGetFiles = (
  caseId: Case["id"],
  requestParams?: GetFilesRequest
) => {
  const queryClient = useQueryClient();
  return useQuery({
    queryKey: queryKeys.getFilesKey(caseId, requestParams),
    queryFn: () => getCaseFiles({ queryClient, caseId, requestParams }),
    refetchInterval: (files) =>
      // if a source is initialized we want to wait to poll to make sure the source has been added to the IRSCaseFiles
      // table before we overwrite the optimistic update with the real data
      isAnySourceInitialized(files)
        ? INITIAL_UPLOAD_WAIT_TIME_IN_MS
        : isAnySourceUploadInProgress(files) || isAnySourceDeleting(files)
        ? FILE_POLLING_IN_MS
        : false,
  });
};

import { useCallback } from "react";
import { useTranslation } from "react-i18next";

import {
  FileUpload,
  GetFilesResponse,
} from "../api/endpoints/sources/fileGetApiTypes";
import {
  isSourceStatusInProgress,
  isStatusDeleteInProgress,
} from "../api/endpoints/sources/utils/sourceStatus";
import { isWallet } from "../pages/source-view/utils/isWallet";

export enum BannerMessageType {
  SINGLE_FILE_INGESTING = "SingleFileIngesting",
  SINGLE_WALLET_INGESTING = "SingleWalletIngesting",
  MULTIPLE_SOURCES_INGESTING = "MultipleSourcesIngesting",
  SINGLE_SOURCE_DELETING = "SingleSourceDeleting",
  MULTIPLE_SOURCES_DELETING = "MultipleSourcesDeleting",
  SOURCES_DELETING_AND_INGESTING = "SourcesDeletingAndIngesting",
  TAX_CALCS_IN_PROGRESS = "TaxCalcsInProgress",
}

const getSingleSourceName = (source: FileUpload) => {
  if (isWallet(source.sourceType)) {
    return source.walletAddress ?? source.label;
  }
  return source.fileName ?? source.label;
};

export const getBannerMessageType = (sourcesInProgress: GetFilesResponse) => {
  const sourcesDeleting = sourcesInProgress.filter((source) =>
    isStatusDeleteInProgress(source.status)
  );
  const sourcesIngesting = sourcesInProgress.filter((source) =>
    isSourceStatusInProgress(source.status)
  );
  if (sourcesDeleting.length > 0 && sourcesIngesting.length > 0) {
    return BannerMessageType.SOURCES_DELETING_AND_INGESTING;
  }
  if (sourcesDeleting.length > 0) {
    return sourcesDeleting.length === 1
      ? BannerMessageType.SINGLE_SOURCE_DELETING
      : BannerMessageType.MULTIPLE_SOURCES_DELETING;
  }
  if (sourcesIngesting.length > 0) {
    if (sourcesIngesting.length === 1) {
      return isWallet(sourcesIngesting[0].sourceType)
        ? BannerMessageType.SINGLE_WALLET_INGESTING
        : BannerMessageType.SINGLE_FILE_INGESTING;
    }
    return BannerMessageType.MULTIPLE_SOURCES_INGESTING;
  }
  return BannerMessageType.TAX_CALCS_IN_PROGRESS;
};

export type UseGetBannerMessageResult = {
  getBannerMessage: (sourcesInProgress: GetFilesResponse) => string;
};

export const useGetBannerMessage = (): UseGetBannerMessageResult => {
  const { t } = useTranslation();

  return {
    getBannerMessage: useCallback(
      (sourcesInProgress: GetFilesResponse) => {
        const messageType = getBannerMessageType(sourcesInProgress);
        switch (messageType) {
          case BannerMessageType.SINGLE_FILE_INGESTING: {
            return t(messageType, {
              fileName: getSingleSourceName(sourcesInProgress[0]),
            });
          }
          case BannerMessageType.SINGLE_WALLET_INGESTING: {
            return t(messageType, {
              walletAddress: getSingleSourceName(sourcesInProgress[0]),
            });
          }
          case BannerMessageType.SINGLE_SOURCE_DELETING: {
            return t(messageType, {
              sourceName: getSingleSourceName(sourcesInProgress[0]),
            });
          }
          default: {
            return t(messageType);
          }
        }
      },
      [t]
    ),
  };
};
